body {
    background-color: #FFFFFF;
}
.main-content {
    background-color: #FFFFFF;
    color: #252b3b
}
.btn-outline-primary:hover{
    background-color: #0bb197 !important;
  
    color:white !important;
  }
  .btn-outline-danger,
  .btn-outline-primary,
  .btn-outline-info,
  .btn-outline-secondary {
      background-color: transparent !important;
  }
  .btn-outline-danger:hover 
  .btn-outline-primary:hover,
  .btn-outline-info:hover,
  .btn-outline-secondary:hover {
      color:#505d69 !important;
  }
  
.homePageBody {
    background-color: #FFFFFF;
    color: #252b3b
}

#homeBanner {
    width: 100%;
    background-color: #252b3b;
    color: #EEEEEE !important;

    border-top: 1px solid #EEEEEE;

}

#homeBanner h1 {
    font-size: 4em;
    color: #EEEEEE !important
}

#homeBanner h2 {
    font-size: 3.5em;
    color: #EEEEEE !important
}

#homeBanner p {
    font-size: 1.5em;
    line-height: 1.8em;
    color: #EEEEEE !important
}


#homeFooter {
    padding: 0.5em;
    height: 3em;
    text-align: center;
    font-size: 0.8em;
    color: #EEEEEE !important;
    background-color: #252b3b;

}

#HomeSecondaryContent {
    color: #EEEEEE !important;
    background-color: #252b3b;

}

#homePageContactForm h1,
#homePageContactForm h2,
#homePageContactForm h3,
#homePageContactForm h4,
#homePageContactForm h5,
#homePageContactForm h6,
#HomeSecondaryContent h1,
#HomeSecondaryContent h2,
#HomeSecondaryContent h3,
#HomeSecondaryContent h4,
#HomeSecondaryContent h5,
#HomeSecondaryContent h6,
#homePageContactForm.inputForm {
    color: #D1D1D6 !important;
}



a.dark {
    color: #fcb92c !important
}

/* Dark Mode */
.darkMode,
.darkMode .main-content,
#root .darkMode,
#layout-wrapper .darkMode .darkMode .page-content {
    background-color: #000000 !important;
    /*#202124*/
    color: #D1D1D6 !important;

}

#layout-wrapper {
    min-height: 100% !important
}

/* Text color in dark mode */
.darkMode p,
.darkMode h1,
.darkMode h2,
.darkMode h3,
.darkMode h4,
.darkMode h5,
.darkMode h6 {
    color: #D1D1D6 !important;
}

/* Apex Charts text color */
.apex-charts text {
    fill: rgb(80, 93, 105)!important;
}

/* Line break handling */
.lp-copy-sel__option,
.lp-copy-sel__single-value {
    white-space: pre-wrap !important;
}

/* Dark Mode card styles */
.navbar-brand-box .darkMode,
.darkMode .navbar-header,
.darkMode .card,
.darkMode .card-body,
.darkMode .card-body table,
.darkMode .card-body p,
.darkMode .card-body h5,
.darkMode .text-info,
.darkMode .tabMuiTableBody-root,
.logo-dark .darkMode,
#page-topbar .darkMode,
.darkMode .simplebar-wrapper,
.darkMode .vertical-menu {
    background-color: #000000 !important;
    color: #8E8E93 !important;
    border-radius: 10px !important;
}

.alert-container {

    height: 100%;
    max-height: 300px;
    overflow: auto !important;
    display: flex !important;

    flex-direction: column-reverse !important;

}

.darkMode .table,
.darkMode .table td,
.darkMode .table th,
.dark-mode .form-control,
.dark-mode .form-control input,
.darkMode table thead th,
.darkMode .input-group-text,
.darkMode .select2-selection,

#sidebar-menu .darkMode,

#sidebar-menu .darkMode ul {
    background-color: #000000 !important;
    /*#2C2C2E*/
    border-color: #AEAEB2 !important;
    color: #AEAEB2 !important;
}

table,
table tbody tr,
table th,
table thead th {
    background-color: transparent !important;
    color: #000000 !important;
}


.darkMode table tbody tr:hover td {
    background: #000000 !important;
}

.darkMode .page-content {

    border-left: 1px dashed #3A3A3C !important;
}

.darkMode .card-body {
    border: 1px dashed #3A3A3C !important;
}

moondance-input:checked {
    background-color: #fcb92c;
}

input {
    background-color: transparent !important;

    color: inherit !important
}

.btn-primary,
.badge .bg-success {
    color: #FFFFFF !important;

}

.MoondanceTable table {

    background-color: transparent !important;
    display: block;
    overflow: auto;

}

.select2-dropdown {
    z-index: 10060 !important;
    /*1051;*/
}

.largeModal {
    min-width: 60% !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
}

.subFilterDropdown {
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
    display: block;
    font-weight: 400;
    margin-left: 25px;

}

.subFilter input {
    margin-right: 1em;

}

.ownersCheckboxDropdown {
    margin-right: 8px;
}

.danger-slider::-webkit-slider-thumb {
    background-color: red !important;
}

.danger-slider {
    background-color: blue;
}

.btn-outline-danger,
.btn-outline-primary,
.btn-outline-info,
.btn-outline-secondary {
    background-color: transparent !important;
}

.btn-outline-info:hover {
    background-color: #4aa3ff !important;
}

.btn-outline-danger:hover {
    background-color: #ff3d60 !important;
}

.btn-outline-primary:hover {
    background-color: #0bb197 !important;
    color: #000000 !important;

}

.btn-link:hover {
    background-color: #0bb197 !important;
}

.btn-outline-secondary:hover {
    background-color: #74788d !important;
}

.apexcharts-tooltip {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
}

.apexcharts-tooltip-title {
    font-size: 15px;
    margin-bottom: 4px;
}

.apexcharts-tooltip-series {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.apexcharts-tooltip-marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.equal-height-card {
    min-height: 90% !important;
    /* You can adjust this value as needed */
    display: flex;
    flex-direction: column;
}

.info {
    color: #4aa3ff !important;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pct-form {
    position: relative;
}

.pct-form::after {
    content: '%';
    /* Add the '%' symbol */
    position: absolute;
    top: 50%;
    /* Adjust the vertical position as needed */
    right: 10px;
    /* Adjust the horizontal position as needed */
    transform: translateY(-50%);
    pointer-events: none;
    /* Make the '%' symbol non-interactive */
}

@media (min-width: 1026px) {
    .mobileOnly {
        display: none !important;
    }
}


@media only screen and (max-width: 1026px) {
    #page-topbar {
        max-height: 2.5em;
        margin: 0px !important;

    }

    .navbar-header {
        max-height: 2.5em;
        margin: 0px !important;
    }

    .header-item {
        max-height: 2.5em !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        font-size: 1em !important;
    }

    .navbar-brand-box {
        display: none !important
    }

    .page-content {
        padding-top: 3em !important;
    }

    .hideOnMobile {
        display: none !important;
    }

    .mobileOnly {
        display: visible !important;
    }


    .ms-2 {
        margin-top: 0.2em !important;
    }

    .vertical-menu {
        top: 2em !important;
        width: 100% !important;
    }

    .homeContainer {
        padding: 2em !important;
    }

    .publicHomePage li {
        padding-bottom: 1em;
        padding-top: 1em;
    }

    #homeBanner {

        width: 100%;
        margin-top: 10px;
    }

    #homeBanner h2 {
        line-height: 1.6em;
        font-size: 1.4em;
    }

    #homeBanner p {
        line-height: 1.8em;
        font-size: 1.1em;
    }



    #home-topbar {
        background-color: #252b3b;
        height: 70px;
    }

    #home-topbar img {
        max-height: 50px;
    }




    #homeCarousel img {
        margin-bottom: 2em;
    }
}

@media only screen and (min-width: 1026px) {



    #home-topbar {
        background-color: #252b3b;
        color: #FFFFFF;
        height: 80px;
    }

    .publicHomePage li {
        padding-bottom: 1em;
        padding-top: 1em;
    }

    .homeContainer {
        padding: 10em;
        line-height: 2em;
    }

    #HomeSecondaryContent li {
        font-size: 1em;
        padding-top: 0.6em;
    }





    .homePageContactForm {
        margin-top: 10em;
        padding: 2em;
        margin-top: 2em;
        margin-bottom: 2em;
        
    }


    #homePageContactForm.inputForm {
        background-color: #252b3b !important;

    }

    #homeCarousel p {
        font-size: 1.2em;
        color: #252b3b !important;
    }

    #homeCarousel h5 {
        font-size: 1.4em;
        color: #252b3b !important;
    }

    #homeCarousel img {
        max-width: 80%;
        margin-left: 10%
    }
}

@media print {
    body {
        margin: 0;
        font-size: medium;
        color: #000;
        background-color: #fff;
    }

    .btn {
        display: none !important;
        ;
    }

    .card {
        max-width: 100% !important;
    }

    .MoondanceTable {
        max-width: 100% !important;
    }
}


.tableXlsImportCell {
    border: 1px solid #000000;
    padding: 0.5em;
}

.tableXlsImportCellDisabled {
    border: 1px solid #000000;
    padding: 0.5em;
    font-style: italic;
}

.tableXlsImportHead {

    border: 1px solid #000000;
    background-color: #78E5CD !important;
    margin: 0.1em;
    text-align: center;
}

.tablexlsImportHead th,
.tablexlsImportHead td {
    min-width: 50px;
    /* Ensure each cell has a minimum width */
    /* max-width: 200px; /* and a maximum width, adjust as needed */
    white-space: nowrap;
    /* Prevent text from wrapping */

}

.tablexlsImportHead thead th {
    position: sticky;
    top: 0;
    /* Adjust this value based on your needs */
    background-color: #fff;
    /* Set a background color to prevent see-through when scrolled */
    z-index: 1;
    /* Higher z-index ensures the header stays on top of other content */
    border: 1px solid #000000;

}

.tablexlsImportHead tbody tr,
.tablexlsImportHead th,
.tablexlsImportHead td {
    border: 1px solid black;
    /* Visual style */
}

.tableXlsImportColHead {
    border: 1px solid #000000;
    background-color: #78E5CD !important;
    margin: 0.1em;
    text-align: center;
    position: sticky;
    width: 2em;
    left: 0;
    z-index: 1;
}

.scrollable-table {
    max-height: 400px;
    overflow: auto;
    /* Allows scrolling */
}

.badge .bg-danger {
    background-color: #FF5E78 !important;
}