//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

.btn-light {
    border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

//
// Soft Buttons
//
